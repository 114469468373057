// Config file for fontawesome.
// Read a tutorial on npm page, you can import whole libraries, or specific fonts like done here.
// And then use them, by importing this config etc.

// import the library module
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faFacebookF,faTwitter,faGooglePlusG,faLinkedinIn,faRedditAlien,faWhatsapp} from '@fortawesome/free-brands-svg-icons';

// Add icons to the library
library.add(
  faFacebookF,
  faTwitter,
  faGooglePlusG,
  faLinkedinIn,
  faRedditAlien,
  faWhatsapp
  // more icons go here
);