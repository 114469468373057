import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'

import Layout from '../components/Layout'
import ContactButtons from '../components/ContactButtons'
import MyForm from '../components/MyForm'

class Contact extends React.Component {
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        // const eMail = data.site.siteMetadata.myEmail

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO
                    title="Contact"
                    keywords={[`contact`, `blog`, `portfolio`, `3d`, `graphics`]}
                />
                <h2
                    style={{
                        marginTop: 0,
                        color: `#df6c75`,
                    }}
                >
                    Contact me
                </h2>

                <MyForm/>

                {/*
                <div style={{ fontWeight: `bold`, paddingBottom: `15px` }}>
                You can also contact me directly with e-mail at: <br/> {eMail}
                </div>
                */}

                <h2
                    style={{
                        marginTop: 0,
                        paddingTop: 20,
                        paddingBottom: 15,
                        fontFamily: `Orbitron`,
                        fontWeight: `600`,
                        color: `#4d4d4d`,
                        fontSize: `2.2em`
                    }}
                >
                    Social media
                </h2>
                You can also contact me via social media:
                <ContactButtons />
            </Layout>
        )
    }
}

// Export the class About as the end product.
export default Contact

// Create a query to get an image
export const contactQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        ## myEmail
      }
    }
    illustration: file(absolutePath: { regex: "/doge2.png/" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`